import * as React from "react";
import Layout from "../../components/layout";
import { graphql } from "gatsby";
import { DataProvider, componentMap } from "../../contentful/data-provider";
// import { Helmet } from "react-helmet";
import Seo from "../../components/seo";

const InnovationsPage = ({ data }) => {
	const dataProvider = new DataProvider(data);
	const title = data.allContentfulPage.edges[0].node.title;

	return (
		<Layout dataProvider={dataProvider}>
			<Seo dataProvider={dataProvider} title={`Glassview | ${title}`} />
			<main className='pt-24 lg:pt-24'>
				{dataProvider.getCurrentPageSections().map((section, index) => {
					return (
						<>
							{React.createElement(componentMap[section.type], {
								content: dataProvider.getElementById(section.id, section.locale),
								dataProvider: dataProvider,
							})}
						</>
					);
				})}
			</main>
		</Layout>
	);
};

export default InnovationsPage;

export const query = graphql`
	query ($locale: String!, $contentfulId: String!) {
		allContentfulPage(filter: { contentful_id: { eq: $contentfulId }, node_locale: { eq: $locale } }) {
			edges {
				node {
					...CurrentPageDetails
				}
			}
		}
		allContentfulEntry {
			edges {
				node {
					...AllEntryDetails
				}
			}
		}
		allContentfulMenu(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					...Menus
				}
			}
		}
	}
`;

//comentedhead
